<template>
  <div class="login">
    <header class="header">
      <img
        class="headerLogo"
        style=""
        src="../../assets/images/logo.png"
        alt=""
      />
      <h1 class="title font-s-44 font-w-b header_title">智慧水利综合管理平台</h1>
    </header>
    <main class="main flex flex-a-c flex-j-e" style="position: relative">
      <div class="block" style="position: relative;width:320px;height:350px">
        <h1 class="title font-s-22 font-w-b">用户登录</h1>
        <form id="login" autocomplete="off">
          <label class="login-label">
            <i class="el-icon-mobile-phone"></i>
            <input
              id="user"
              type="text"
              maxlength="11"
              v-model="phone"
              autocomplete="true"
              placeholder="请输入11位手机号"
              :fetch-suggestions="querySearch"
            />
          </label>
          <div class="flex flex-j-s-b">
            <!--        验证码登录的时候去掉这个width宽度    style="width: 800px"-->
            <label class="login-label" style="width: 800px">
              <i class="el-icon-lock"></i>
              <!-- <input type="text" v-model="password" placeholder="请输入验证码" style="width: calc(110 / 1920 * 100vw)"/> -->
              <input
                style="width: 100%"
                type="password"
                v-model="password"
                placeholder="请输入密码"
              />
            </label>
            <!-- 验证码登录的时候打开这个注释 -->
            <!-- <button class="btn-s-m btn-c-blue" type="button" :disabled="loading" @click.prevent="getCode">
             {{ timer }}
           </button> -->
          </div>
          <SliderCheck
            ref="slider"
            :successFun="handleSuccessFun"
            :errorFun="handleErrorFun"
          ></SliderCheck>
          <el-button
            :disabled="!isSlider"
            type="primary"
            class="btn-c-blue btn"
            @click.prevent="toHome"
          >
            登录
          </el-button>
        </form>
        <div class="remember_pass">
          <div class="computLogin">
            <img src="../../assets/images/login/comput.png" alt="" />
            <span class="testcom">智慧水利综合管理平台</span>
          </div>
          <div>
            <el-checkbox v-model="checked">7天内免密登录</el-checkbox>
          </div>
        </div>
      </div>
      <!-- <div class="code">
        <div class="code_box">
          <span class="code_title">移动端下载</span>
          <div class="code_img">

            <div class="qrcode" id="qrcode" ref="qrcode" />
          </div>
        </div>
      </div> -->
    </main>
    <footer class="footer flex flex-j-c" style="position: relative">
      <span class="font-s-20 font-w-500"
        >系统开发：河南橡树智能科技有限公司</span
      >

      <!-- <span class="font-s-20 font-w-500" style="margin-left: calc(50 / 1920 * 100vw)">技术支持：0546-8778677</span> -->
    </footer>
  </div>
</template>

<script>
import { login, code } from "../../api/login";
import { createSocket } from "../../assets/js/webscoket";
import { apk } from "@/utils/methods.js";
import QRCode from "qrcodejs2";
import SliderCheck from "../../components/SliderCheck";

export default {
  components: {
    SliderCheck,
  },
  data() {
    return {
      loading: false,
      phone: "",
      isSlider: false,
      uuid: sessionStorage.getItem("uuid"),
      password: "",
      timer: "获取验证码",
      checked: false,
      restaurants: "",
    };
  },
  created() {
    if (localStorage.getItem("remember") == "true") {
      const time = 604800000;
      // console.log(localStorage.getItem('remember'))
      // console.log(localStorage.getItem('userInfo'))
      const userInfo = JSON.parse(localStorage.getItem("userInfo"));
      if (new Date().getTime() - userInfo.date < time) {
        login({
          userName: userInfo.userName,
          password: userInfo.password,
        }).then((res) => {
          if (res.code == 200) {
            localStorage.setItem("token", res.data.token);
            this.$router.push({ name: "main" });
          }
        });
      } else {
      }
    }
    // localStorage.clear();
    // localStorage.removeItem("token");
  },
  mounted() {
    document.getElementById("qrcode").innerHTML = "";
    new QRCode("qrcode", {
      width: 150,
      height: 150,
      text: apk(process.env.VUE_APP_CURENV),
    });
  },
  methods: {
    // 滑块验证成功回调
    handleSuccessFun() {
      this.isSlider = true;
    },
    // 滑块验证失败回调
    handleErrorFun() {},
    getCode() {
      if (this.phone == "") {
        this.$message.error("请输入手机号");
      } else {
        if (isNaN(this.phone)) {
          this.$message("请输入正确的手机号格式");
        } else {
          code({ userName: this.phone })
            .then((res) => {
              if (res.code == 200) {
                this.$message.success(res.message);
              } else {
                this.$message.error(res.message);
              }
            })
            .catch((err) => {
              if (err.message) {
                this.$message.error(err.message);
                return;
              }
              if (err.data.msg) {
                this.$message.error(err.data.msg);
              }
            });
        }
      }
    },
    // 登录
    toHome() {
      if (this.phone == "" || this.password == "") {
        this.$message.error("请输入手机号或验证码");
      } else {
        if (isNaN(this.phone)) {
          login({
            userName: this.phone,
            password: this.password,
          })
            .then((res) => {
              const arr = [];
              const userInfo = {
                userName: res.data.userName,
                userId: res.data.id,
              };
              localStorage.setItem("token", res.data.token);
              localStorage.setItem("id", res.data.id);
              localStorage.setItem("roleId", res.data.roleId);
              localStorage.setItem("remember", this.checked);
              localStorage.setItem("userName", res.data.userName);
              localStorage.setItem("userInfo", JSON.stringify(userInfo));
              localStorage.setItem("talkbackNumber", res.data.talkbackNumber);
              if (localStorage.getItem("userList")) {
                this.restaurants = JSON.parse(localStorage.getItem("userList"));
              } else {
                arr.push(res.data.userName);
                localStorage.setItem("userList", JSON.stringify(arr));
              }
              this.$router.push({ name: "index" });
              this.$message.success(res.message);
            })
            .catch((err) => {
              if (err.message) {
                this.$message.error(err.message);
                return;
              }
              if (err.data.msg) {
                this.$message.error(err.data.msg);
              }
            });
        } else {
          login({
            userName: this.phone,
            password: this.password,
          })
            .then((res) => {
              const arr = [];
              const userInfo = {
                userName: res.data.userName,
                userId: res.data.id,
              };
              if (localStorage.getItem("userList")) {
                this.restaurants = JSON.parse(localStorage.getItem("userList"));
              } else {
                arr.push(res.data.userName);
                localStorage.setItem("userList", JSON.stringify(arr));
              }
              localStorage.setItem("token", res.data.token);
              localStorage.setItem("id", res.data.id);
              localStorage.setItem("roleId", res.data.roleId);
              localStorage.setItem("userInfo", JSON.stringify(userInfo));
              localStorage.setItem("remember", this.checked);
              localStorage.setItem("userName", res.data.userName);
              localStorage.setItem("talkbackNumber", res.data.talkbackNumber);
              this.$router.push({ name: "index" });
              this.$message.success(res.message);
            })
            .catch((err) => {
              if (err.message) {
                this.$message.error(err.message);
                return;
              }
              if (err.data.msg) {
                this.$message.error(err.data.msg);
              }
            });
        }
        // code({ userName: this.phone }).then(re@s => {
        //   console.log(res);
        //   if (res.code == 200) {
        //     localStorage.setItem("token", res.data.token);
        //     localStorage.setItem("id", res.data.id);
        //     // this.$router.push({ name: "main" });
        //     this.$message.success(res.message);
        //   } else {
        //     this.$message.error(res.message);
        //   }
        // });
      }
    },
    querySearch(queryString, cb) {
      if (JSON.parse(localStorage.getItem("userList").length)) {
        this.restaurants = JSON.parse(localStorage.getItem("userList"));
      } else {
        localStorage.setItem("userList", JSON.stringify());
      }
      const restaurants = this.restaurants;
      // console.log(restaurants)
      cb(restaurants);
    },
  },
};
</script>

//
<style lang="scss" scoped>
// 头部区域
.header {
  // padding: calc(110 / 1080 * 100vh) 0 calc(41 / 1080 * 100vh) 0;
  height: calc(220 / 1080 * 100vh);
  background-color: #fff;
  position: relative;
}
.headerLogo {
  position: absolute;
  width: 3.5vw;
  top: 3.5vw;
  left: 7vw;
}
.header_title{
  position: absolute;
  top: 3.5vw;
  left:12vw;
  letter-spacing:5px
}
// 标题区域
.title {
  color: #445370;
  // text-align: center;
}

// 主体体区域
.main {
  height: calc(650 / 1080 * 100vh);
  background: url("../../assets/images/login/login.png") -50px 0;
  // background-size: 100%;
  // background-repeat:no-repeat;

  .block {
    // width: calc(300 / 1920 * 100vw);
    height: calc(382 / 1080 * 100vh);
    margin-right: calc(124 / 1920 * 100vw);
    padding: 0 calc(30 / 1920 * 100vw);
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 0 0 21px rgba(255, 255, 255, 0.35);

    .title {
      color: #445370;
      text-align: left;
      margin: calc(30 / 1080 * 100vh) 0;
      padding: calc(10 / 1080 * 100vh) 0;
      border-bottom: 1px solid #d2d2d2;
      position: relative;
      font-size: calc(26 / 1920 * 100vw);
    }

    .title::after {
      content: "";
      width: calc(114 / 1920 * 100vw);
      height: calc(4 / 1080 * 100vh);
      background-color: #249fea;
      position: absolute;
      left: 0;
      top: calc(58 / 1080 * 100vh);
    }

    // 用户登录
    .login {
      color: #333333;
      margin: calc(46 / 1080 * 100vh) 0;
    }

    form {
      > div:last-child {
        margin-top: calc(10 / 1080 * 100vh);
      }

      label {
        border: 1px solid #e8ecf7;
        border-radius: 4px;

        > i {
          padding-right: calc(8 / 1920 * 100vw);
          color: #a6b6c6;
          cursor: text;
        }
      }

      .login-label {
        position: relative;

        > i {
          position: absolute;
          font-size: 14px;
          top: calc(50% - 7px);
          left: 7px;
        }

        > input {
          padding-left: 28px;
        }
      }

      input::placeholder {
        color: #a6b6c6;
        font-weight: 500;
        font-size: calc(12 / 1920 * 100vw);
      }

      > button {
        width: calc(271 / 1920 * 100vw);
      }

      input {
        width: 100%;
        height: calc(36 / 1080 * 100vh);

        &[type="checkbox"] {
          width: auto;
        }
      }

      button {
        height: calc(38 / 1080 * 100vh);
        background: #3a87db;
        border-radius: 4px;

        &:disabled {
          background-color: gray;
        }
      }

      .btn {
        width: 100%;
      }
    }

    #login {
      margin-bottom: calc(30 / 1080 * 100vh);
    }

    .remember_pass {
      position: relative;
      display: flex;
      align-items: center;
      color: #9b9b9b;
      top: -10px;
      left: -10px;
      ::v-deep .el-checkbox__inner {
        border-radius: 50%;
      }
    }
  }

  .code {
    position: absolute;
    top: calc(150 / 1080 * 100vh);
    width: 20px;
    padding: 10px 5px 10px 12px;
    line-height: 18px;
    text-align: center;
    background: #ffffff;
    border-top-left-radius: 9px;
    border-bottom-left-radius: 9px;

    &:hover {
      cursor: pointer;

      & > .code_box > .code_img {
        opacity: 1;
        right: 36px;
        transition: right 0.5s;
      }
    }

    .code_box {
      position: relative;

      .code_title {
        color: #0084ff;
      }

      .code_img {
        position: absolute;
        top: -9px;
        right: 0px;
        opacity: 0;
        padding: 5px;
        background: #ffffff;
        padding: 5px;

        .qrcode {
          width: 150px;
          height: 150px;
        }
      }
    }
  }
}

// 底部区域
.footer {
  margin-top: calc(20 / 1080 * 100vh);
  color: #0d3158;
}

form {
  > div:last-child {
    margin-top: calc(50 / 1080 * 100vh);
  }
}

label,
.label {
  display: flex;
  align-items: center;
  margin-bottom: calc(18 / 1080 * 100vh);

  > .label-text {
    width: calc(120 / 1920 * 100vw);
    display: inline-block;
    font-size: calc(16 / 1920 * 100vw);
    font-weight: 500;
    color: #778ca2;
  }
}

::v-deep .el-checkbox {
  position: absolute;
  top: 1px;
  margin-left: 12px;
  color: #778ca2;
}

.computLogin {
  display: flex;
  align-items: center;
  margin-right: 5px;
}

.testcom {
  color: #3a87db;
  margin-left: 10px;
}
</style>
