<template>
  <div class="slider-check-box">
    <div class="slider-check" :class="rangeStatus ? 'success' : ''">
      <i ref="rangePan" @mousedown="rangeMove" class="check-i" :class="rangeStatus ? successIcon : startIcon"></i>
      <span  style="margin-left:65px;line-height: 30px">{{ rangeStatus ? successText : startText }}</span>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    // 成功之后的函数
    successFun: {
      type: Function
    },
    //成功图标
    successIcon: {
      type: String,
      default: 'el-icon-success'
    },
    //成功文字
    successText: {
      type: String,
      default: '验证成功'
    },
    //开始的图标
    startIcon: {
      type: String,
      default: 'el-icon-d-arrow-right'
    },
    //开始的文字
    startText: {
      type: String,
      default: '请拖住滑块，拖动到最右边'
    },
    //失败之后的函数
    errorFun: {
      type: Function
    },
    //或者用值来进行监听
    status: {
      type: String
    }
  },
  data() {
    return {
      disX: 0,
      rangeStatus: false
    }
  },
  methods: {
    init(){
      this.disX = 0,
      this.rangeStatus = false
      this.$refs.rangePan.style.transform =  'translateX(0px)'
      this.$refs.rangePan.style.transition = 'all 0.1s ease 0s'
      // this.$refs.rangePan.style.color = 'red'
      // ({'transform': , 'transition': 'all 0.1s ease 0s'})
    },
    //滑块移动
    rangeMove(e) {
      let ele = e.target
      let startX = e.clientX
      let eleWidth = ele.offsetWidth
      let parentWidth = ele.parentElement.offsetWidth
      let MaxX = parentWidth - eleWidth
      if (this.rangeStatus) {
        //不运行
        return false
      }
      document.onmousemove = e => {
        let endX = e.clientX
        this.disX = endX - startX
        if (this.disX <= 0) {
          this.disX = 0
        }
        if (this.disX >= MaxX - eleWidth) {
          //减去滑块的宽度,体验效果更好
          this.disX = MaxX
        }
        ele.style.transition = '.1s all'
        ele.style.transform = 'translateX(' + this.disX + 'px)'
        e.preventDefault()
      }
      document.onmouseup = () => {
        if (this.disX !== MaxX) {
          ele.style.transition = '.5s all'
          ele.style.transform = 'translateX(0)'
          //执行成功的函数
          this.errorFun && this.errorFun()
        } else {
          this.rangeStatus = true
          if (this.status) {
            this.$parent[this.status] = true
          }
          //执行成功的函数
          this.successFun && this.successFun()
        }
        document.onmousemove = null
        document.onmouseup = null
      }
    }
  }
}
</script>
<style lang="less" scoped>
@blue: #198eeb;
@mixin jc-flex {
  display: flex;
  justify-content: center;
  align-items: center;
}
.slider-check-box {
  margin-bottom: 20px;
  .slider-check {
    height: 30px;
    border-radius: 4px;
    background-color: #e9e9e9;
    position: relative;
    transition: 1s all;
    user-select: none;
    color: #585858;
    @include jc-flex;
    height: 30px;
    &.success {
      background-color: @blue;
      color: #fff;
      i {
        color: @blue;
      }
    }
    i {
      border-radius: 4px;
      position: absolute;
      left: 0;
      width: 50px;
      height: 100%;
      color: @blue;
      background-color: #fff;
      border: 1px solid #d8d8d8;
      cursor: pointer;
      font-size: 24px;
      @include jc-flex;
      &:before{
        position: absolute;
        top: 3px;
        left: 10px;
      }
    }
  }
}
</style>
